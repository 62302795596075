<template>
  <div
    v-if="data && item"
    class="product-cart"
    :class="{
      'product-cart__wholesale-item': wholesale_fixed,
      'product-cart__parent': is_parent,
    }"
  >
    <router-link
      :to="{
        name: 'product',
        params: {
          id: item.parent_id ? item.parent_id : item.id,
          link: item.link || (item.parent && item.parent.link),
        },
      }"
      class="product-cart__image"
    >
      <ImgComponent :head_img="item_img" width="90" height="100" />
    </router-link>
    <div class="product-cart__content">
      <router-link
        :to="{
          name: 'product',
          params: {
            id: item.parent_id ? item.parent_id : item.id,
            link: item.link || (item.parent && item.parent.link),
          },
        }"
        class="product-cart__image"
      >
        <ImgComponent :head_img="item_img" width="90" height="100" />
      </router-link>
      <div class="product-cart__title">
        <router-link
          v-if="item.brand && item.brand.title"
          :to="{ name: 'catalog', query: { brand_id: item.brand.id, page_title: item.brand.title } }"
        >
          {{ item.brand.title }}
        </router-link>
        <span v-if="item.brand && item.brand.title"> / </span>
        <router-link
          v-if="item.title"
          :to="{
            name: 'product',
            params: {
              id: item.parent_id ? item.parent_id : item.id,
              link: item.link || (item.parent && item.parent.link),
            },
          }"
        >
          {{ item.title }}
        </router-link>
      </div>
      <div class="product-cart__subtitle-list">
        <div
          v-if="item.variation_one || item.variation_two || item.variation_three"
          class="product-cart__subtitle-row"
        >
          <span v-if="item.variation_one" class="product-cart__subtitle">
            {{ item.variation_one.attribute.title }}: {{ item.variation_one.value.value }}
          </span>
          <span v-if="item.variation_two" class="product-cart__subtitle">
            {{ item.variation_two.attribute.title }}: {{ item.variation_two.value.value }}
          </span>
          <span v-if="item.variation_three" class="product-cart__subtitle">
            {{ item.variation_three.attribute.title }}: {{ item.variation_three.value.value }}
          </span>
        </div>
        <span class="product-cart__subtitle">Коледино Sasahub</span>
        <span v-if="item.shop && item.shop.fio" class="product-cart__subtitle">
          <span>{{ item.shop.fio }}</span>
          <RemixIconComponent category="System" name="information-line" />
        </span>
      </div>
    </div>
    <div class="product-cart__count">
      <button type="button" class="btn btn--sm btn--hollow-dark" @click="change(false)">
        <RemixIconComponent category="System" name="indeterminate-circle-line" />
      </button>
      <span v-html="data.count"></span>
      <button type="button" class="btn btn--sm btn--hollow-dark" @click="change(true)">
        <RemixIconComponent category="System" name="add-circle-line" />
      </button>
    </div>
    <div v-if="item.price" class="product-cart__prices">
      <span
        class="product-cart__price"
        v-html="
          $options.filters.price_from_dollar(
            $options.filters.share_price(item.price * data.count, item),
            $store.state
          )
        "
      >
      </span>
      <span
        v-if="item.join_share && item.join_share.share"
        class="product-cart__old-price"
        v-html="$options.filters.price_from_dollar(item.price * data.count, $store.state)"
      >
      </span>
    </div>
    <div class="product-cart__actions">
      <button
        :class="{ 'product-cart__action--active': inFavorites }"
        type="button"
        class="product-cart__action"
        @click.prevent="addToFavorites"
      >
        <FileIconComponent v-if="loading" name="loading" />
        <RemixIconComponent v-else category="Health" name="heart-3-line" />
      </button>
      <button
        @click="removeCartItem"
        type="button"
        class="product-cart__action product-cart__action--red product-cart__action--remove"
      >
        <RemixIconComponent category="System" name="delete-bin-6-line" />
      </button>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import LoginModal from "components/modals/components/LoginModal.vue";
import FileIconComponent from "components/FileIconComponent.vue";

export default {
  name: "ProductCardCartVersionComponent",
  props: {
    data: Object,
    wholesale_fixed: Boolean,
    is_parent: Boolean,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    item() {
      return this.data.item;
    },
    item_img() {
      if (this.item.head_img) {
        return this.item.head_img;
      }
      if (this.item.images && this.item.images.length) {
        return this.item.images[0];
      }
      if (this.item.parent && this.item.parent.head_img) {
        return this.item.parent.head_img;
      }
      return null;
    },
    inFavorites() {
      return this.$store.state.auth.favorites.includes(this.item.id);
    },
    isRegistered() {
      return !!this.$store.state.apollo_token;
    },
    sellingType() {
      if (
        this.item.in_wholesale &&
        this.item.parent &&
        this.item.parent.wholesale &&
        this.item.parent.wholesale.length
      ) {
        return this.types.WHOLESALE;
      }
      if (this.item.in_wholesale && this.item.step && this.item.step !== 1) {
        return this.types.STEP;
      }
      // if (this.item.retail) {
      //   return this.types.RETAIL;
      // }
      // return this.types.NOT_SELLING;
      return this.types.RETAIL;
    },
    types() {
      return this.$store.state._types;
    },
    wholesaleVariation() {
      return ["variation_one", "variation_two", "variation_three"].find(
        (key) =>
          this.item.parent && this.item.parent.wholesale[0].attribute_id === this.item[key].attribute.id
      );
    },
  },
  methods: {
    /**
     * Изменение количества товаров в корзине
     * increase == true - увеличение
     * increase == false - уменьшение
     * @param increase
     */
    change(increase) {
      let count;
      if (this.sellingType === this.types.WHOLESALE) {
        if (this.wholesaleVariation) {
          let wholesale = this.item.parent.wholesale;
          // ищем входит ли данная вариация в комплект его родителя
          // по идее можно убрать проверку, т.к. если это WHOLESALE, то это значит что товар в комплекте
          let setInWholesale = wholesale.find(
            (item) => item.attribute_value.id === this.item[this.wholesaleVariation].value.id
          );
          // если входит, то изменяем количество всех товаров в комплекте
          if (setInWholesale) {
            // берем все товары в корзине, которые входят в тот же комплект
            let wholesaleSets = this.getItemWholesaleSets(this.item);
            wholesaleSets.forEach((set) => {
              let changedCount = this.getCount(this.item, wholesale);
              set.count = increase ? set.count + changedCount : set.count - changedCount;
              if (set.count <= 0) {
                this.remove(wholesaleSets.map((i) => (i.id ? i.id : i.item.id)));
              }
            });
            this.$emit(
              "countChange",
              wholesaleSets.map((set) => {
                return {
                  id: set.item.id,
                  count: set.count,
                };
              })
            );
          }
        }
      } else if (this.sellingType === this.types.STEP) {
        // минимально можно купить STEP штук товара
        if (increase) {
          if (this.data.count >= this.item.step) {
            count = this.data.count + 1;
          } else {
            count = this.item.step;
          }
        } else {
          if (this.data.count <= this.item.step) {
            count = 0;
          } else {
            count = this.data.count - 1;
          }
        }
        if (count <= 0) {
          this.remove(this.data.id ? [this.data.id] : [this.item.id]);
        } else {
          this.$emit("countChange", [{ id: this.item.id, count }]);
        }
      } else {
        count = increase ? this.data.count + 1 : this.data.count - 1;
        if (count <= 0) {
          this.remove(this.data.id ? [this.data.id] : [this.item.id]);
        } else {
          this.$emit("countChange", [{ id: this.item.id, count }]);
        }
      }
    },
    removeCartItem() {
      if (this.sellingType === this.types.WHOLESALE) {
        this.remove(this.getItemWholesaleSets(this.item).map((i) => (i.id ? i.id : i.item.id)));
      } else {
        this.remove(this.data.id ? [this.data.id] : [this.item.id]);
      }
    },
    remove(ids) {
      // if (confirm("Вы точно хотите удалить выбранные товары?\nОтменить действие будет невозможно")) {
      this.$emit("remove", ids);
      // }
    },
    async addToFavorites() {
      if (this.isRegistered) {
        let index = this.$store.state.auth.favorites.indexOf(this.item.id);
        if (index !== -1) {
          this.$store.state.auth.favorites.splice(index, 1);
        } else {
          this.$store.state.auth.favorites.push(this.item.id);
        }
        await this.$store.dispatch("FAVORITE_CREATE_OR_DELETE", {
          apollo: this.$apollo,
          variables: {
            item_id: this.item.id,
          },
        });
      } else {
        this.showLoginModal();
      }
    },
    /**
     * Получение товаров из того же комплекта в корзине, что и вариация в параметрах
     * @param item
     * @returns {*[]}
     */
    getItemWholesaleSets(item) {
      let wholesale = item.parent.wholesale;
      return this.$store.state.auth.cart.filter(
        (c) =>
          c.item.parent_id === item.parent_id &&
          wholesale.map((i) => i.attribute_value.id).includes(c.item[this.wholesaleVariation].value.id) &&
          ["variation_one", "variation_two", "variation_three"].filter((variation) => {
            // если это не атрибут комплекта, то проверяем
            if (variation !== this.wholesaleVariation) {
              // либо у обоих нет этой variation_... либо у них одинаковые значения атрибута
              return (
                c.item[variation] === item[variation] ||
                c.item[variation].value.id === item[variation].value.id
              );
            } else {
              // если это атрибут комплекта, то значение атрибута должно входить в комплект
              return wholesale.map((w) => w.attribute_value.id).includes(c.item[variation].value.id);
            }
          }).length === 3
      );
    },
    /**
     * Получение count продукта из wholesale
     * @param item
     * @param wholesale
     * @returns {number}
     */
    getCount(item, wholesale) {
      return (
        wholesale.find((i) => i.attribute_value.id === item[this.wholesaleVariation].value.id).count || 0
      );
    },
    showLoginModal() {
      this.$store.state._modals.push({
        component: LoginModal,
        options: {},
      });
    },
  },
  components: { FileIconComponent, RemixIconComponent },
};
</script>

<style lang="stylus">
.product-cart {
  width 100%
  display grid
  grid-template-columns 90px 1fr 120px 140px 80px
  gap 16px
  align-items flex-start
  justify-content space-between
  +below(1200px) {
    grid-template-columns 90px 1fr 120px auto 80px
  }
  +below(1100px) {
    display flex
    grid-template-columns none
    & > &__image {
      display none
    }
  }
  +below(1024px) {
    display grid
    grid-template-areas "a a" "b c" "d d"
    align-items center
  }

  &:not(:last-child) {
    border-bottom 1px solid var(--blue-400)
    padding-bottom 8px
  }

  &__wholesale-item {
    +below(1100px) {
      display: grid;
      grid-template-columns: 1fr 120px auto 80px;
      justify-content: flex-end;
    }
    +below(1024px) {
      display flex
    }

    .product-cart__content {
      +below(1024px) {
        display flex
        align-items flex-start
      }
      +below(580px) {
        display grid
        grid-template-areas: "a b" "c c";
      }
    }

    .product-cart__subtitle-row {
      +below(440px) {
        flex-direction column
        gap 0
      }
    }

    .product-cart__title,
    .product-cart__count > button,
    .product-cart__action--remove {
      display none
    }
  }

  &__parent &__image {
    width 90px
    height 100px
  }

  &__image {
    max-width 90px
    flex-shrink 0

    img {
      border-radius: var(--small_radius);
      width 100%
      height 100%
      object-fit contain
      object-position center
      background var(--blue-400)
    }
  }

  &__content &__image {
    +below(1100px) {
      grid-area a
    }
    +above(1101px) {
      display none
    }
  }

  &__content {
    flex-grow 1
    display grid
    +below(1100px) {
      grid-template-areas "a b" "c c"
      gap 16px
      justify-content flex-start
    }
    +below(1024px) {
      grid-area a
    }
  }

  &__title {
    margin-bottom 4px
    +below(1100px) {
      grid-area b
    }

    * {
      font-weight: normal;
      font-size: 0.875em;
      line-height: 20px;
      color: var(--body-color);
    }
  }

  &__subtitle {
    font-weight: normal;
    font-size: 0.75em;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--gray-300);
    gap 4px

    .icon {
      width 16px
      height 16px

      svg path {
        fill var(--blue-900)
      }
    }
  }

  &__subtitle-list {
    display grid
    +below(1100px) {
      grid-area c
    }
  }

  &__subtitle-row {
    display flex
    gap 16px
  }

  &__count {
    display flex
    gap 4px
    align-items center
    justify-content center
    flex-shrink 0
    +below(1024px) {
      grid-area b
      justify-content flex-start
    }

    > .btn:hover {
      background var(--blue-200)
    }

    > span {
      font-weight: 500;
      font-size: 0.875em;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: var(--body-color);
      width 40px
      height 40px
      justify-content center
    }
  }

  &__prices {
    display flex
    flex-direction column
    align-items flex-end
    justify-content center
    flex-shrink 0
    +below(1024px) {
      grid-area c
    }
  }

  &__price {
    font-weight: 600;
    font-size: 1.125em;
    line-height: 24px;
    color: var(--gray-900);
  }

  &__old-price {
    font-weight: normal;
    font-size: 0.875em;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-decoration-line: line-through;
    color: var(--gray-300);
  }

  &__actions {
    display flex
    flex-shrink 0
    justify-content flex-end
    +below(1024px) {
      grid-area d
      justify-content space-between
    }
  }

  &__action {
    padding 8px
    background none
    border none
    cursor pointer
    border-radius var(--small_radius)
    transition var(--transition)
    display flex
    justify-content center
    align-items center

    &.product-cart__action--active {
      .icon svg path {
        fill var(--accent)
      }
    }

    &:hover {
      background-color var(--blue-200)
    }

    &:active {
      transform scale(0.96)
    }

    .icon {
      width 16px
      height 16px
      +below(1024px) {
        width 24px
        height 24px
      }

      svg {
        path {
          fill var(--body-color-muted)
        }

        circle {
          stroke var(--body-color-muted)
        }
      }
    }

    &--red .icon svg path {
      fill var(--red-500)
    }
  }
}
</style>
