<template>
  <div class="cart-page page">
    <div class="cart-page__container">
      <h1 class="cart-page__title">Корзина</h1>
      <div v-if="cartItems && cartItems.length" class="cart-page__content">
        <div class="cart-page__body">
          <div v-for="(item, i) in modifiedCartItems" :key="i" class="cart-page__product">
            <template v-if="Array.isArray(item)">
              <ProductCardCartVersionComponent
                v-for="(product, index) in item"
                :key="index"
                :data="product"
                @countChange="countChange"
                @remove="remove"
                :wholesale_fixed="index !== 0"
              />
            </template>
            <ProductCardCartVersionComponent
              v-else
              :data="item"
              @countChange="countChange"
              @remove="remove"
              :is_parent="true"
            />
          </div>
          <div v-if="deliveries && deliveries.length" class="form">
            <span class="form__title">Способы доставки</span>
            <div class="form__row">
              <div v-for="(item, i) in deliveries" :key="i" class="form__col-4">
                <RadioComponent
                  :checked="isChecked(item)"
                  @change="changeRadio($event, item)"
                  :title="item.description"
                >
                  {{ item.title }}
                </RadioComponent>
              </div>
            </div>
          </div>
          <div ref="deliveryForm" class="form">
            <span class="form__title">Доставка</span>
            <div class="form__row">
              <div class="form__col-6">
                <InputComponent
                  v-model="deliveryForm.city.value"
                  title="Город*"
                  placeholder="Москва"
                  :subtitle="deliveryForm.city.message"
                  :error="deliveryForm.city.error"
                />
              </div>
              <div class="form__col-6">
                <InputComponent
                  v-model="deliveryForm.street.value"
                  title="Улица*"
                  placeholder="Гагарина"
                  :subtitle="deliveryForm.street.message"
                  :error="deliveryForm.street.error"
                />
              </div>
              <div class="form__col-6">
                <InputComponent
                  v-model="deliveryForm.house.value"
                  title="Дом*"
                  placeholder="3"
                  :subtitle="deliveryForm.house.message"
                  :error="deliveryForm.house.error"
                />
              </div>
              <div class="form__col-6">
                <InputComponent
                  v-model="deliveryForm.flat.value"
                  title="Квартира"
                  placeholder="6"
                  :subtitle="deliveryForm.flat.message"
                  :error="deliveryForm.flat.error"
                />
              </div>
              <div class="form__col-4">
                <InputComponent
                  v-model="deliveryForm.entrance.value"
                  title="Подъезд"
                  placeholder="2"
                  :subtitle="deliveryForm.entrance.message"
                  :error="deliveryForm.entrance.error"
                />
              </div>
              <div class="form__col-4">
                <InputComponent
                  v-model="deliveryForm.code.value"
                  title="Код"
                  placeholder="363"
                  :subtitle="deliveryForm.code.message"
                  :error="deliveryForm.code.error"
                />
              </div>
              <div class="form__col-4">
                <InputComponent
                  v-model="deliveryForm.floor.value"
                  title="Этаж"
                  placeholder="8"
                  :subtitle="deliveryForm.floor.message"
                  :error="deliveryForm.floor.error"
                />
              </div>
              <div class="form__col-12">
                <InputComponent
                  v-model="deliveryForm.commentary.value"
                  title="Комментарий к адресу"
                  placeholder="Комментарий к адресу"
                  :subtitle="deliveryForm.commentary.message"
                  :error="deliveryForm.commentary.error"
                />
              </div>
            </div>
          </div>
          <div ref="recipientForm" class="form">
            <span class="form__title">Данные получателя</span>
            <div class="form__row">
              <div class="form__col-6">
                <InputComponent
                  v-model="recipientForm.name.value"
                  title="Имя*"
                  placeholder="Имя"
                  :subtitle="recipientForm.name.message"
                  :error="recipientForm.name.error"
                />
              </div>
              <div class="form__col-6">
                <InputComponent
                  v-model="recipientForm.surname.value"
                  title="Фамилия*"
                  placeholder="Фамилия"
                  :subtitle="recipientForm.surname.message"
                  :error="recipientForm.surname.error"
                />
              </div>
              <div class="form__col-6">
                <InputComponent
                  v-model="recipientForm.phone.value"
                  title="Номер телефона"
                  placeholder="Номер телефона"
                  mask="+7 (###) ### - ## - ##"
                  :subtitle="recipientForm.phone.message"
                  :error="recipientForm.phone.error"
                />
              </div>
              <div class="form__col-6">
                <InputComponent
                  v-model="recipientForm.email.value"
                  title="Email"
                  placeholder="Email"
                  :subtitle="recipientForm.email.message"
                  :error="recipientForm.email.error"
                />
              </div>
            </div>
          </div>
        </div>
        <CartPageAsideComponent
          :loading="loading"
          :delivery="deliveryForm.delivery_id.value"
          :sum="orderSum"
          :discount="discount"
          :price_delivery="price_delivery"
          @pay="orderCreate"
          @setPromocode="setPromocode"
          @removePromocode="removePromocode"
        />
      </div>
      <span v-else>Корзина пуста</span>
    </div>
  </div>
</template>

<script>
import CartPageAsideComponent from "views/cart/components/AsideComponent.vue";
import ProductCardCartVersionComponent from "components/product/ProductCardCartVersionComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import RadioComponent from "components/inputs/RadioComponent.vue";
import ORDER_CREATE from "@/graphql/mutations/OrderCreate.graphql";
import LoginModal from "components/modals/components/LoginModal.vue";
import PRICE_DELIVERY from "@/graphql/queries/price_delivery.graphql";

export default {
  name: "CartPage",
  async asyncData({ apollo, store }) {
    await store.dispatch("GET_CART_ITEMS", {
      apollo: apollo.defaultClient,
    });
  },
  data() {
    return {
      loading: false,
      debounceTimeout: null,
      price_delivery: 0,
      deliveryForm: {
        delivery_id: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: true,
        },
        city: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: true,
        },
        street: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        house: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        flat: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        entrance: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        code: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        floor: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        commentary: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        promocode: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
      },
      recipientForm: {
        name: {
          value: undefined,
          error: undefined,
          message: undefined,
        },
        surname: {
          value: undefined,
          error: undefined,
          message: undefined,
        },
        phone: {
          value: undefined,
          error: undefined,
          message: undefined,
        },
        email: {
          value: undefined,
          error: undefined,
          message: undefined,
        },
      },
    };
  },
  watch: {
    "$store.state.auth.user"() {
      this.setUserData();
    },
    "$route.name"() {
      this.removePromocode();
    },
    "$route.params"() {
      this.removePromocode();
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.deliveries && this.deliveries.length) {
        this.deliveryForm.delivery_id.value = this.deliveries[0];
        this.getPriceDelivery();
      }
      this.removePromocode();
    });
    if (this.isRegistered) {
      this.setUserData();
    }
  },
  computed: {
    // изменяем вид корзины, чтобы удобнее было выводить комплекты
    modifiedCartItems() {
      let items = [];
      this.cartItems.forEach((cartItem) => {
        // если эта вариация еще не добавлена
        if (!items.flat().find((i) => i.item && cartItem.item && i.item.id === cartItem.item.id)) {
          // если товар в комплекте
          if (
            cartItem.item &&
            cartItem.item.in_wholesale &&
            cartItem.item.parent &&
            cartItem.item.parent.wholesale &&
            cartItem.item.parent.wholesale.length
          ) {
            // берем все товары в корзине, которые входят в тот же комплект
            let wholesaleSets = this.getItemWholesaleSets(cartItem.item);
            items.push(wholesaleSets);
          } else {
            items.push(cartItem);
          }
        }
      });
      return items;
    },
    cartItems() {
      return this.$store.state.auth.cart;
    },
    isRegistered() {
      return !!this.$store.state.apollo_token || this.user;
    },
    deliveries() {
      return this.$store.state.auth.deliveries;
    },
    user() {
      return this.$store.state.auth.user;
    },
    orderSum() {
      return this.cartItems
        .map((r) => this.$options.filters.share_price(r.item?.price, r.item) * r.count)
        .reduce((a, b) => a + b, 0);
    },
    discount() {
      return (
        this.cartItems.map((r) => parseFloat(r.item?.price) * r.count).reduce((a, b) => a + b, 0) -
        this.orderSum
      );
    },
  },
  methods: {
    /**
     * Вывод скидки по промокоду
     */
    setPromocode(share, promocode) {
      this.deliveryForm.promocode.value = promocode;
      let promoProducts = share.products.map((p) => p.id);
      this.cartItems.forEach((c) => {
        if (promoProducts.includes(c.item?.id)) {
          c.item.promo_share = { share };
        }
      });
    },
    /**
     * Удаление промокода
     */
    removePromocode() {
      this.cartItems.forEach((c) => {
        delete c.item.promo_share;
      });
    },
    /**
     * Подставить личные данные
     */
    setUserData() {
      if (this.user) {
        this.recipientForm.name.value = this.user.name;
        this.recipientForm.surname.value = this.user.surname;
        this.recipientForm.email.value = this.user.email;
        this.recipientForm.phone.value = this.user.phone;
      }
    },
    /**
     * Оплата
     */
    orderCreate() {
      if (this.isRegistered) {
        if (!this.loading) {
          this.loading = true;
          this.resetErrors("deliveryForm");
          this.resetErrors("recipientForm");
          let errorsCount = 0;
          Object.keys(this.deliveryForm).forEach((key) => {
            if (this.deliveryForm[key].required && !this.deliveryForm[key].value) {
              this.deliveryForm[key].error = "Заполните поле";
              errorsCount += 1;
            }
          });
          if (!errorsCount) {
            this.$apollo
              .mutate({
                mutation: ORDER_CREATE,
                variables: {
                  name: this.recipientForm.name.value,
                  surname: this.recipientForm.surname.value,
                  phone: "+" + this.recipientForm.phone.value.replace(/\D+/g, ""),
                  email: this.recipientForm.email.value,
                  commentary: this.deliveryForm.commentary.value,
                  promocode: this.deliveryForm.promocode.value,
                  address: [
                    this.deliveryForm.city.value ? "город " + this.deliveryForm.city.value : null,
                    this.deliveryForm.street.value ? "улица " + this.deliveryForm.street.value : null,
                    this.deliveryForm.house.value ? "дом " + this.deliveryForm.house.value : null,
                    this.deliveryForm.entrance.value ? "подъезд " + this.deliveryForm.entrance.value : null,
                    this.deliveryForm.code.value ? "код " + this.deliveryForm.code.value : null,
                    this.deliveryForm.floor.value ? "этаж " + this.deliveryForm.floor.value : null,
                    this.deliveryForm.flat.value ? "квартира " + this.deliveryForm.flat.value : null,
                  ]
                    .filter((i) => i)
                    .join(", "),
                  delivery_id: this.deliveryForm.delivery_id.value
                    ? this.deliveryForm.delivery_id.value.id
                    : null,
                },
                context: {
                  headers: {
                    Authorization: "Bearer " + this.$store.state.apollo_token,
                  },
                },
              })
              .then(({ data }) => {
                this.loading = false;
                if (data && data.OrderCreate) {
                  if (data.OrderCreate.payment_url) {
                    let link = document.createElement("a");
                    link.href = data.OrderCreate.payment_url;
                    link.click();
                  }
                }
              })
              .catch(({ graphQLErrors }) => {
                this.loading = false;
                this.parseGqlErrors(graphQLErrors);
                this.scrollTo("recipientForm");
              });
          } else {
            this.loading = false;
            this.scrollTo("deliveryForm");
          }
        }
      } else {
        this.$store.state._modals.push({
          component: LoginModal,
          options: {
            routeAfterLogin: {
              name: this.$router.currentRoute.name,
              params: this.$router.currentRoute.params,
              hash: this.$router.currentRoute.hash,
            },
          },
        });
      }
    },
    /**
     * Изменение количества товаров в корзине
     * @param items
     * @returns {Promise<void>}
     */
    async countChange(items) {
      // изменение на фронте до запроса, чтобы быстрее отобразилось
      items.forEach((item) => {
        this.$store.state.auth.cart.find((c) => c.item.id === item.id).count = item.count;
      });
      // костыль, изменяем массив, чтобы вызвался watch в App.vue
      // и сохранил изменения в localStorage
      this.$store.state.auth.cart.push({});
      this.$store.state.auth.cart.pop();

      await this.getPriceDelivery();

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => {
        if (this.isRegistered) {
          this.$store.dispatch("CART_ADD_ITEMS", {
            apollo: this.$apollo,
            variables: {
              items: items,
            },
          });
        }
      }, 500);
    },
    /**
     * Удаление товаров в корзине
     * @param ids
     * @returns {Promise<void>}
     */
    async remove(ids = []) {
      this.$store.state.auth.cart = this.$store.state.auth.cart.filter((c) =>
        c.id ? !ids.includes(c.id) : !ids.includes(c.item.id)
      );
      await this.getPriceDelivery();
      if (this.isRegistered) {
        await this.$store.dispatch("CART_DELETE_ITEMS", {
          apollo: this.$apollo,
          variables: {
            id: ids,
          },
        });
      }
    },
    /**
     * Получение цены за доставку
     * @returns {Promise<void>}
     */
    async getPriceDelivery() {
      let total = 0;
      let price_weight = this.deliveryForm.delivery_id.value?.price_weight || 0;
      let price_volume = this.deliveryForm.delivery_id.value?.price_volume || 0;
      this.cartItems.forEach((c) => {
        if (c.item.weight) {
          total += c.item.weight * price_weight * c.count;
        } else if (c.item.volume) {
          total += c.item.volume * price_volume * c.count;
        }
      });
      this.price_delivery = total;
    },
    /**
     * Изменение способа доставки
     * @param e
     * @param item
     */
    changeRadio(e, item) {
      if (e) {
        this.deliveryForm.delivery_id.value = item;
        this.getPriceDelivery();
      }
    },
    isChecked(item) {
      return JSON.stringify(this.deliveryForm.delivery_id.value) === JSON.stringify(item);
    },
    /**
     * Получение товаров из того же комплекта в корзине, что и вариация в параметрах
     * @param item
     * @returns {*[]}
     */
    getItemWholesaleSets(item) {
      let wholesale = item.parent.wholesale;
      return this.$store.state.auth.cart.filter(
        (c) =>
          c.item.parent_id === item.parent_id &&
          wholesale
            .map((i) => i.attribute_value.id)
            .includes(c.item[this.wholesaleVariation(item)].value.id) &&
          ["variation_one", "variation_two", "variation_three"].filter((variation) => {
            // если это не атрибут комплекта, то проверяем
            if (variation !== this.wholesaleVariation(item)) {
              // либо у обоих нет этой variation_... либо у них одинаковые значения атрибута
              return (
                c.item[variation] === item[variation] ||
                c.item[variation].value.id === item[variation].value.id
              );
            } else {
              // если это атрибут комплекта, то значение атрибута должно входить в комплект
              return wholesale.map((w) => w.attribute_value.id).includes(c.item[variation].value.id);
            }
          }).length === 3
      );
    },
    wholesaleVariation(item) {
      return ["variation_one", "variation_two", "variation_three"].find(
        (key) => item.parent && item.parent.wholesale[0].attribute_id === item[key].attribute.id
      );
    },
    /**
     * Скролл до нужного элемента
     * @param refName
     */
    scrollTo(refName) {
      window.scrollTo({ top: this.$refs[refName].offsetTop, behavior: "smooth" });
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.recipientForm[key]) {
              this.recipientForm[key].error = err.extensions.validation[key][0];
            }
          });
        }
      });
    },
    resetErrors(form) {
      Object.keys(this[form]).forEach((key) => {
        this[form][key].error = undefined;
      });
    },
  },
  metaInfo: {
    title: "Корзина",
  },
  components: { RadioComponent, InputComponent, ProductCardCartVersionComponent, CartPageAsideComponent },
};
</script>

<style lang="stylus">
.cart-page {
  display flex
  justify-content center
  width 100%
  padding 32px 15px
  +below(860px) {
    padding 15px
  }

  &__container {
    max-width var(--main_width)
    width 100%
    display grid
    align-items start
    gap 24px
  }

  &__title {
    font-weight: normal;
    font-size: 2.25em;
    line-height: 50px;
    display: flex;
    align-items: center;
    margin 0
    color: var(--body-color);
  }

  &__content {
    display grid
    grid-gap 24px
    grid-template-columns 9fr 3fr
    align-items start
    margin-bottom 80px
    +below(1200px) {
      grid-template-columns 8fr 4fr
    }
    +below(860px) {
      grid-template-columns 1fr
      grid-gap 30px
      margin-bottom 0
    }
  }

  .form__col-4 {
    +below(716px) {
      gap 16px
      flex-basis calc(100% - 16px)
    }
  }

  .form__col-6 {
    +below(540px) {
      gap 16px
      flex-basis calc(100% - 16px)
    }
  }

  &__body {
    display grid
    grid-gap 8px

    .form__row .input {
      width 100%
    }
  }

  &__product {
    display flex
    flex-direction column
    gap 8px
    background: var(--white);
    border-radius: var(--main_radius);
    border: 1px solid var(--blue-400);
    padding 16px
    transition var(--transition)

    &:hover {
      border-color var(--accent)
    }
  }
}
</style>
