<template>
  <label
    class="radio"
    :class="{
      'radio--disabled': disabled,
      'radio--checked': checked,
      'radio--error': error,
    }"
  >
    <span class="radio__state"></span>
    <input
      type="checkbox"
      :checked="checked"
      :disabled="disabled"
      @change="$emit('change', $event.target.checked)"
    />
    <slot />
  </label>
</template>

<script>
export default {
  name: "RadioComponent",
  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    error: Boolean,
    checked: Boolean,
    disabled: Boolean,
  },
};
</script>

<style lang="stylus">
.radio {
  display flex
  gap 8px
  position relative
  cursor pointer
  align-items: center;
  text-align: left;
  user-select none
  font-size: 0.8750em; // 14px
  line-height: 18px;
  transition var(--transition)

  &:hover {
    color var(--accent)

    .radio__state {
      border: 1px solid var(--accent);
    }
  }

  input {
    absolute left top
    visibility hidden
  }

  &--error {
    color var(--red-500) !important

    .radio__state {
      border-color var(--red-500) !important
    }
  }

  &--disabled {
    color var(--gray-50)

    .radio__state {
      border: 1px solid var(--gray-50);
    }

    &.radio--checked {
      .radio__state {
        border: 1px solid var(--gray-50);

        &:after {
          background: var(--gray-50);
        }
      }
    }
  }

  &--checked:not(.radio--disabled) {
    color var(--black)

    .radio__state {
      border-color var(--accent)

      &:after {
        background: var(--accent);
      }
    }
  }

  &__state {
    width 20px
    height 20px
    background: var(--white);
    border: 1px solid var(--blue-500);
    box-sizing: border-box;
    border-radius: 50%
    display flex
    align-items center
    flex-shrink 0
    justify-content center
    transition var(--transition)
    position relative

    &:after {
      content: "";
      width 10px
      height 10px
      flex-shrink 0
      border-radius 100%
      background transparent
      transition .3s
    }
  }
}
</style>
