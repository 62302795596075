<template>
  <div class="cart-page-aside">
    <div class="cart-page-aside__total">
      <span>Итого</span>
      <span v-html="$options.filters.price_from_dollar(sum, $store.state)"></span>
    </div>
    <ul class="cart-page-aside__info">
      <li v-if="discount">
        <span>Скидка</span>
        <span v-html="$options.filters.price_from_dollar(discount, $store.state)"></span>
      </li>
      <li v-if="promocode_discount">
        <span>Скидка по промокоду</span>
        <span v-html="$options.filters.price_from_dollar(promocode_discount, $store.state)"></span>
      </li>
      <template v-if="delivery">
        <li class="cart-page-aside__price-delivery" v-if="price_delivery">
          <span>Доставка (не входит в оплату)</span>
          <span v-html="$options.filters.price_from_dollar(price_delivery, $store.state, true)"></span>
        </li>
        <li v-if="delivery.time_delivery">
          <span>Дата доставки</span>
          <span v-html="delivery.time_delivery"></span>
        </li>
      </template>
    </ul>
    <div class="cart-page-aside__footer">
      <CheckboxComponent :checked="agree" @change="agree = !agree">
        <div>
          Согласен с условиями
          <a href="/files/terms.pdf" target="_blank">Правил пользования торговой площадкой</a> и
          <a href="/files/terms.pdf" target="_blank">правилами возврата</a>
        </div>
      </CheckboxComponent>
      <button type="button" class="btn btn--lg btn--main" :disabled="!agree" @click="pay">
        <FileIconComponent v-if="loading" name="loading" />
        <template v-else>Оплатить</template>
      </button>
    </div>
    <div class="cart-page-aside__promocode">
      <button
        v-if="!showPromoField"
        @click="showPromoField = true"
        type="button"
        class="btn btn--sm btn--main-inv"
      >
        У меня есть промокод
      </button>
      <template v-else>
        <InputComponent
          v-model.trim="promocode"
          title="Промокод"
          icon-position="right"
          @action="checkPromocode"
          v-if="!activePromocode"
        >
          <template v-slot:icon>
            <FileIconComponent v-if="loadingPromocode" name="loading" />
            <RemixIconComponent v-else category="System" name="arrow-right-s-line" />
          </template>
        </InputComponent>
        <div v-else class="cart-page-aside__promocode-active">
          <span>{{ activePromocode }}</span>
          <button @click="removePromocode" title="Удалить промокод" type="button" class="btn">
            <RemixIconComponent category="System" name="close-line" />
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import CheckboxComponent from "components/inputs/CheckboxComponent.vue";
import FileIconComponent from "components/FileIconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";
import SHARE_PROMOCODE from "@/graphql/queries/share_promocode.graphql";

export default {
  name: "CartPageAsideComponent",
  props: {
    delivery: Object,
    loading: Boolean,
    sum: Number,
    discount: Number,
    promocode_discount: Number,
    price_delivery: Number,
  },
  data() {
    return {
      agree: true,
      loadingPromocode: false,
      showPromoField: false,
      activePromocode: undefined,
      promocode: undefined,
    };
  },
  methods: {
    pay() {
      if (this.agree) {
        this.$emit("pay");
      }
    },
    async checkPromocode() {
      if (!this.loadingPromocode && this.promocode) {
        this.loadingPromocode = true;
        await this.$apollo.provider.defaultClient
          .query({
            query: SHARE_PROMOCODE,
            variables: {
              promocode: this.promocode,
            },
          })
          .then(({ data }) => {
            this.loadingPromocode = false;
            if (data.share_promocode) {
              this.activePromocode = this.promocode;
              this.$emit("setPromocode", data.share_promocode, this.promocode);
              this.$notify({
                title: "Успешно",
                text: "Промокод активирован",
                type: "success",
              });
            } else {
              this.$notify({
                title: "Ошибка",
                text: "Неверный промокод",
                type: "error",
              });
            }
          })
          .catch(() => {
            this.loadingPromocode = false;
            this.$notify({
              title: "Ошибка",
              text: "Введен неверный промокод",
              type: "error",
            });
          });
      }
    },
    removePromocode() {
      this.activePromocode = undefined;
      this.$emit("removePromocode");
    },
  },
  components: { RemixIconComponent, InputComponent, FileIconComponent, CheckboxComponent },
};
</script>

<style lang="stylus">
.cart-page-aside {
  position sticky
  top 24px
  background: var(--white);
  border-radius: var(--main_radius);
  border: 1px solid var(--blue-400);
  padding 16px
  display grid
  grid-gap 8px
  align-items start

  &__total {
    display flex
    align-items center
    justify-content space-between
    padding-bottom 8px
    border-bottom 1px solid var(--blue-500)
    width 100%

    span {
      font-weight: normal;
      font-size: 1.5em;
      line-height: 32px;
      display: flex;
      align-items: center;
      color: var(--body-color);
    }
  }

  &__info {
    display grid
    grid-gap 8px
    width 100%

    li {
      display flex
      width 100%
      gap 8px
      justify-content space-between
      padding-bottom 8px
      border-bottom 1px solid var(--blue-500)

      span {
        font-weight: normal;
        font-size: 0.875em;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: var(--gray-200);

        &:last-child {
          flex-shrink 0
        }
      }
    }
  }

  &__footer {
    display: grid;
    grid-gap 16px
    width 100%

    .check {
      align-items flex-start
    }

    .btn {
      justify-content center
    }
  }

  &__promocode {
    display grid

    .input__action {
      cursor: pointer;
      transition var(--transition)
      border-radius: 0 var(--small_radius) var(--small_radius) 0;

      &:hover {
        background var(--accent)
      }
    }

    &-active {
      display flex
      align-items center
      justify-content space-between

      span {
        color var(--green)
      }

      .btn {
        padding 5px

        .icon {
          width 20px
          height 20px

          svg path {
            transition var(--transition)
          }
        }

        &:hover {
          background var(--blue-400)

          svg path {
            stroke var(--red-400)
          }
        }
      }
    }
  }
}
</style>
